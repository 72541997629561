import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Home/properties")
)


//Components
import Main from '../components/Home/main'
import About from '../components/Home/about-home'
import Developments from '../components/Home/developments'
import Tasaciones from "../components/Home/tasaciones"
import Investors from '../components/investors'
import Novelties from '../components/Home/novelties'
import ContactWrapper from '../components/ContactWrapper'
// import FixedBtn from '../components/fixed-btn'

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Inicio" />
    <Main />
    <About />
    {!isSSR && (
      <React.Suspense fallback={<div />}>
        <ClientSideOnlyLazy />
      </React.Suspense>
    )}
    <Developments />
    <Tasaciones/>
    <Investors />
    <Novelties />
    <ContactWrapper/>
    {/* <FixedBtn 
      linkTo="/tasaciones"
      linkTxt="Tasaciones en el día"/> */}
  </Layout>
)}

export default IndexPage
