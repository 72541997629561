import React, {useRef} from 'react'
import Card from '../development-card'
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import {getFeatured} from '../../helpers/helper.developments'
import OwlCarousel from 'react-owl-carousel3'

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:300,
    center:true,
    margin:15,
    dots: true,
    dotsEach: true,
    startPosition:0,
    items:3,
    responsive: {
        0: {
            autoWidth:true,
            items: 3,
        },
        400: {
            autoWidth:true,
            items: 3,
        },
        600: {
            autoWidth:true,
            items: 3,
        },
        991: {
            autoWidth:false,
            items: 3,
        },
        992: {
            items: 1,
        }
    },
}

const developments = ({developments,dispatch}) => {

    const slider = useRef()
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                  home {
                    developments {
                      subtitle
                      sup_title
                      title
                      button {
                        link
                        value
                      }
                    }
                  }
                }
              }
        }`)

    return developments?.data?.objects?.length > 0 && getFeatured(developments?.data?.objects).length > 0 ? (
        <section id="featured-devs">
            <div class="container-fluid pt-0 mt-lg-3 pt-lg-3">
                <div class="row">
                    <div class="col-12">
                        <p class="section-title pb-5">Proyectos en Pozo - Construcción - Finalizados</p>
                    </div>
                    <div class="col-12">
                        <h2 class="section-subtitle">
                            Emprendimientos <br /> destacados
                        </h2>
                    </div>
                </div>
            </div>
            <div id="slider-section" className='position-relative featured-devs-home'>
                <OwlCarousel ref={slider}
                    {...options}
                    className="overflow-hidden">
                    {getFeatured(developments.data.objects).map((development,index) => (
                        <Card key={index} development={development}/>
                    ))}
                </OwlCarousel>
                <div className={"arrows-owl-isq zindex-1"}>
                    <div className="arrow cursor-pointer" onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                </div>
                <div className={"arrows-owl-der zindex-1"}>
                    <div className="arrow cursor-pointer" onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                </div>
            </div>
            <div class="devs mt-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-center">
                            <Link to={realEstate.sections.home.developments.button.link} 
                                class="btn btn-red mt-5">
                                Ver todos los emprendimientos
                                <i className="icon-arrow-stick-light"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separator"></div>
        </section> 
    ) : ''
}

export default connect(state => ({
    developments: state.developments.developments,
}),null)(developments);