import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {capitalizeText} from '../helpers/helper.rendering'
import { Link } from "gatsby";
import { graphql, useStaticQuery } from 'gatsby';


const Investors = () => {

    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                        investors {
                            hidden
                            title
                            paragraph
                            button {
                              link
                              value
                            }
                            items
                          }
                        }
                    }
                }
    }`)

    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])
    
    return realEstate.sections && (
        !realEstate.sections.home.investors.hidden  ? (
        <section className="investors">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {/* {capitalizeText(realEstate.sections.home.investors.title)} */}
                        <p class="headline pb-5">{realEstate.sections.home.investors.title}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <h2 className="title">{realEstate.sections.home.investors.paragraph}</h2>
                    </div>
                </div>
                <div className="d-flex grid-wrapper justify-content-center">
                    <div className="grid my-5">
                        <div data-aos="fade-right" className="item-investors d-flex flex-column align-items-center">
                            <i className="icon-building-light mb-lg-5 mb-4"></i>
                            <p className="mb-lg-5 mb-2 text-center">    Dto de Arquitectura <br /> Seguimiento de Obra <br /> Estudio de prefactibilidad</p>
                        </div>
                        <div data-aos="fade-right" className="item-investors d-flex flex-column align-items-center">
                            <i className="icon-investment-light mb-lg-5 mb-4"></i>
                            <p className="mb-lg-5 mb-2 text-center">Dto. Publicidad <br /> Diseño Gráfico <br /> Marketing</p>
                        </div>
                        <div data-aos="fade-right" className="item-investors d-flex flex-column align-items-center">
                            <i className="icon-broker-access-light mb-lg-5 mb-4"></i>
                            <p className="mb-lg-5 mb-2 text-center">Asesoría Jurídica <br /> Asesoría Contable <br /> Asesoría Notarial</p>
                        </div>
                        <div data-aos="fade-right" className="item-investors d-flex flex-column align-items-center">
                            <i className="icon-investor-access-light mb-lg-5 mb-4"></i>
                            <p className="mb-lg-5 mb-2 text-center">Administración de alquileres</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Link data-aos="fade-in" to={realEstate.sections.home.investors.button.link} className="btn btn-red">
                        {/* {realEstate.sections.home.investors.button.value} */}
                        Contactanos ahora
                        <i className="icon-arrow-stick-light"></i>
                    </Link>
                </div>
                {/* <div className="separator"></div> */}
            </div>
        </section>
        ):''
    )
}

export default Investors;