import React, { useEffect, useRef } from "react"
import Searcher from "../search"
import { connect } from "react-redux"
import { getDevelopmentsAction } from "../../redux/developmentsDucks"
import { getTotalListFilters } from "../../redux/propertiesDucks"
import Loading from "../Loading"
import videoHome from "../../videos/video_home_2.mp4"
import imgHome from "../../videos/video_home_mobile.png"
import imgHomeDefault from "../../videos/bg_dic.jpg"
import OwlCarousel from "react-owl-carousel3"

//Helpers
import { getLastFeatured, getLocation } from "../../helpers/helper.developments"
import { graphql, useStaticQuery } from "gatsby"
import {
  getActionByPage,
  getActionByType,
  getCodeFromType,
  getCodes,
} from "../../helpers/helper.actions"

const Home_Main = ({ developments, loading, api_key, dispatch, actions }) => {
  const slider = useRef()

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        slogan
        main_image
      }
    }
  `)

  const slider_action = getActionByPage(
    getActionByType(actions, "Slider de Imagenes Encabezado"),
    "Inicio"
  )
  const image_action = getActionByPage(
    getActionByType(actions, "Imagen Encabezado"),
    "Inicio"
  )
  const video_action = getActionByPage(
    getActionByType(actions, "Video Encabezado"),
    "Inicio"
  )

  useEffect(() => {
    if (api_key) {
      dispatch(getDevelopmentsAction()) // Get all developments
      dispatch(getTotalListFilters()) // Get all developments
    }
  }, [api_key])

  const getSize = () => (screen.width < 992 ? false : true)

  const goTo = () => {
    if (video_action[0].url_destiny) {
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
    }
  }

  return !loading ? (
    slider_action.length > 0 ||
    (video_action.length > 0 && screen.width > 992) ? ( //Caso Actions video/slider
      <>
        {slider_action.length > 0 && (
          <>
            <div id="section-galery-home" className="galery-home">
              <div id="dev-secuencial">
                <div className="slider slider-inicio" id="dev-gallery">
                  <OwlCarousel
                    items={1}
                    ref={slider}
                    margin={0}
                    nav={false}
                    dots={true}
                    loop={true}
                    rewind={true}
                    autoplay={true}
                    autoplayTimeout={
                      parseInt(slider_action[0]?.transition_time) * 1000
                    }
                    id="prop-gallery"
                  >
                    {slider_action[0].files.map((file, index) => (
                      <div
                        className="item banner-main banner-ficha emprendimiento"
                        key={index}
                      >
                        <img
                          src={file.file}
                          className="object-cover object-center cursor-pointer"
                          alt={"#"}
                        />
                        <div className="info-banner position-absolute">
                          <div className="info row align-items-center text-center justify-content-center">
                            <div className="col-8 col-lg-12">
                              <a
                                target={"_blank"}
                                href={
                                  file?.url_destiny + slider_action[0].text_utm
                                }
                                className={
                                  "link-gallery " +
                                  (!file.url_destiny && "d-none")
                                }
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                  <div className={"arrows-owl-isq zindex-1"}>
                    <div
                      className="arrow"
                      onClick={() => slider.current.prev()}
                    >
                      {" "}
                      <i></i> <i></i>
                    </div>
                  </div>
                  <div className={"arrows-owl-der zindex-1"}>
                    <div
                      className="arrow"
                      onClick={() => slider.current.next()}
                    >
                      {" "}
                      <i></i> <i></i>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute-text-push w-100">
                  <div class="container" style={{ zIndex: "1" }}>
                      <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-lg-3">
                          <p>Bienvenidos</p>
                          <h1 className="d-none d-lg-block">
                            {realEstate?.slogan}
                          </h1>
                          <h1 className="d-block d-lg-none">
                            ENCONTREMOS EL LUGAR <br /> QUE BUSCAS
                          </h1>
                        </div>
                      </div>
                  </div>
                  <div className="d-lg-block d-none">
                    <Searcher />
                  </div>
                  <div className="d-lg-none d-block">
                    <Searcher />
                </div>
              </div>
            </div>
          </>
        )}
        {video_action.length > 0 && (
          <>
            {getSize() ? (
              <>
                <div
                  id="home-main"
                  className={
                    "banner-main isVideo " +
                    (video_action[0]?.url_destiny === ""
                      ? "cursor-default"
                      : "cursor-pointer")
                  }
                  onClick={() => goTo()}
                >
                  <div className="absolute-text-push w-100">
                  <div class="container" style={{ zIndex: "1" }}>
                      <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-lg-3">
                          <p>Bienvenidos</p>
                          <h1 className="d-none d-lg-block">
                            {realEstate?.slogan}
                          </h1>
                          <h1 className="d-block d-lg-none">
                            ENCONTREMOS EL LUGAR <br /> QUE BUSCAS
                          </h1>
                        </div>
                      </div>
                  </div>
                  <Searcher />

              </div>
                  <video className={" d-none d-lg-block "} loop autoPlay muted>
                    <source src={video_action[0]?.files?.filter(video => video.device === 'desktop')?.[0].file ?? ''} type="video/mp4" />
                  </video>
                </div>
              </>
            ) : (
              <>
                <div id="home-main" class="banner-main">
                  <div class="item d-flex justify-content-center text-center align-items-start align-items-lg-center position-relative">
                    <div class="opacity"></div>
                    <img
                      src={imgHome}
                      alt="DIC Propiedades - Home"
                      id="home-img"
                    />
                    <div class="container" style={{ zIndex: "1" }}>
                      <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-lg-5">
                          <p>Bienvenidos</p>
                          <h1 className="d-none d-lg-block">
                            {realEstate?.slogan}
                          </h1>
                          <h1 className="d-block d-lg-none">
                            ENCONTREMOS EL LUGAR <br /> QUE BUSCAS
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-lg-none d-block">
                  <Searcher />
                </div>
              </>
            )}
          </>
        )}
      </>
    ) : (
      // Casos sin actiones (Con emprendimientos o sin).
      <>
        {developments?.data?.objects?.length &&
        !image_action &&
        getLastFeatured(developments.data.objects) ? (
          <>
            <div
              id="home-main"
              class="banner-main"
              style={{
                backgroundImage:
                  "url(" +
                  (developments.data
                    ? getCover(
                        getLastFeatured(developments.data.objects).photos
                      )?.original
                    : imgHomeDefault) +
                  ")",
              }}
            >
              <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                <div class="container" style={{ zIndex: "1" }}>
                  <div class="row align-items-center text-center justify-content-center">
                    <div class="col-12 pb-5">
                      <h1>
                        {developments.data &&
                          getLastFeatured(developments.data.objects).name}
                      </h1>
                      <p>
                        {developments.data &&
                          getLocation(
                            getLastFeatured(developments.data.objects),
                            true
                          )[0]}
                        <br />
                        {developments.data &&
                          getLocation(
                            getLastFeatured(developments.data.objects),
                            true
                          )[1]}
                      </p>
                      {/* <a href={developments.data && makeLink(getLastFeatured(developments.data.objects))} target="_blank" class="btn btn-blue mb-5">DESCUBRILO</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Searcher />
          </>
        ) : (
          <>
            <div
              id="home-main"
              onClick={() =>
                image_action[0]?.url_destiny
                  ? window.open(
                      image_action[0].url_destiny + image_action[0].text_utm,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  : ""
              }
              className={
                "banner-main nativo " +
                (image_action.length === 0 ? "" : "banner-img-home")
              }
              style={
                getSize()
                  ? {
                      backgroundImage:
                        "url(" +
                        (image_action.length > 0
                          ? image_action[0]?.files[0].file
                          : imgHomeDefault) +
                        ")",
                    }
                  : {
                      backgroundImage:
                        "url(" +
                        (image_action.length > 0
                          ? image_action[0]?.files[0].file
                          : imgHome) +
                        ")",
                    }
              }
            >
              {image_action.length === 0 ? (
                <div class="item d-flex justify-content-center text-center align-items-start align-items-lg-center position-relative">
                  <div className="opacity"></div>
                  <div class="container" style={{ zIndex: "1" }}>
                    <div class="row align-items-center text-center justify-content-center">
                      <div class="col-12 pb-lg-5">
                        <p>Bienvenidos</p>
                        <h1 className="d-none d-lg-block">
                          {realEstate?.slogan}
                        </h1>
                        <h1 className="d-block d-lg-none">
                          ENCONTREMOS EL LUGAR <br /> QUE BUSCAS
                        </h1>
                        <div className="d-flex searcher-img-home">
                          <Searcher />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                  <>
                  <div className="absolute-text-push w-100">
                    <div class="container" style={{ zIndex: "1" }}>
                        <div class="row align-items-center text-center justify-content-center">
                          <div class="col-12 pb-lg-3">
                            <p>Bienvenidos</p>
                            <h1 className="d-none d-lg-block">
                              {realEstate?.slogan}
                            </h1>
                            <h1 className="d-block d-lg-none">
                              ENCONTREMOS EL LUGAR <br /> QUE BUSCAS
                            </h1>
                          </div>
                        </div>
                    </div>
                    <Searcher />
                </div>
                </>
              )}
            </div>

          </>
        )}
      </>
    )
  ) : (
    <Loading absolute={true} />
  )

  // return !loading ? (
  //     developments?.data?.objects?.length && getLastFeatured(developments.data.objects) ? (
  //     <div id="home-main" class="banner-main">
  //         <div class="item d-flex justify-content-center text-center align-items-start align-items-lg-center position-relative">
  //             <div class="opacity"></div>
  //             {getSize() &&<video id="home-video" loop muted autoPlay>
  //                 <source src={videoHome} type="video/mp4"/>
  //                 Tu navegador no soporta el video HTML.
  //             </video>}
  //             <img src={imgHome} alt="DIC Propiedades - Home" id="home-img"/>
  //             <div class="container" style={{zIndex:"1"}}>
  //                 <div class="row align-items-center text-center justify-content-center">
  //                     <div class="col-12 pb-lg-5">
  //                         <p>Bienvenidos</p>
  //                         <h1 className='d-none d-lg-block'>{realEstate?.slogan}</h1>
  //                         <h1 className='d-block d-lg-none'>ENCONTREMOS EL LUGAR <br /> QUE BUSCAS</h1>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className="d-lg-none d-block">
  //                 <Searcher />
  //             </div>
  //         </div>
  //         <div className="d-none d-lg-block">
  //             <Searcher />
  //         </div>
  //     </div>
  //     ):
  //     <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + realEstate?.main_image + ")"}} >
  //         <div class="item d-flex justify-content-center text-center align-items-center">
  //             <div class="opacity"></div>
  //             <div class="container" style={{zIndex:"1"}}>
  //                 <div class="row align-items-center text-center justify-content-center">
  //                     <div class="col-12 pb-5">
  //                          <h1>
  //                             {realEstate?.slogan}
  //                         </h1>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //         <Searcher />
  //     </div>
  // ):
  // <Loading absolute={true} />
}

export default connect(
  state => ({
    developments: state.developments.developments,
    actions: state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Home_Main)
