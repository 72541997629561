import { Link } from "gatsby"
import React,{ useEffect } from "react"
import NoveltyCard from "../novelty-card";
import {getNoveltiesAction} from "../../redux/noveltiesDucks"
import {getThree, getFeatured, getNotFeatured} from '../../helpers/helper.novelty'
import ContentLoader from "react-content-loader"
import { connect } from "react-redux";

const Novelties = ({novelties,dispatch}) => {
    //loading_novelties, lo saco de las props
    useEffect(() => {
        dispatch(getNoveltiesAction())
    },[])

    const featuredFirst = (data) => {
        let nov = [];
        if (getFeatured(data)?.length > 0) {
            nov.push(...getFeatured(data))
            nov.push(...getNotFeatured(data))
            return nov
        } else {
            return data
        }
    }

    return novelties.length > 0 && (
        <section class="novelties py-5" >
            <div class="opacity"></div>
            <div class="container-fluid py-0 py-lg-4">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <p class="section-name pb-5">Novedades</p>
                    </div>
                    <div class="col-12 text-center">
                        <h2 className="title_novelties">ENTERATE DE LAS ÚLTIMAS TENDENCIAS.</h2>
                    </div>
                    
                    <div class="col-lg-10 pt-0 mt-3">
                        <div class="row py-lg-4">     
                                 {
                                    getThree(featuredFirst(novelties)).map((novelty,index) => (
                                        <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                            <NoveltyCard isHome={true} novelty={novelty} hidenTags={true} />
                                        </div>
                                    ))
                                 }
                        </div>
                    </div>
                    <div class="col-12 mt-5 text-center d-flex justify-content-center ">
                        <Link to="/blog" class="btn btn-red">Ver todas las novedades <i className="icon-arrow-stick-light"></i></Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    // loading_novelties: state.novelties.loading_novelties,
    novelties: state.novelties.novelties,
  }),null)(Novelties);

