import { Link } from "gatsby"
import React, { useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import { capitalizeText } from "../../helpers/helper.rendering";
import $ from 'jquery'
import { connect } from "react-redux";

const About = ({totalCount,totalCountVenta,totalCountAll,totalCountAlquiler,totalCountAlquilerTemp,totalCountAlquilerTemporario}) => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                        about {
                            image
                        }
                    }
                }
    }`)

    useEffect(() => {
        // console.log($(".couter-value"))
    },[])


    return realEstate.sections && (
        <section class="about-us pt-lg-3 mt-lg-3">
            {/* <div class="opacity"></div> */}
            <div class="container-fluid pt-5">
                <div class="row">
                    <div class="col-12 mb-lg-4">
                        <p class="section-name">
                            {capitalizeText(realEstate.sections.home.about.title)}
                        </p>
                    </div>
                    <div class="col-12 my-5">
                        <h2>{realEstate.sections.home.about.paragraph}</h2>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    { realEstate.sections.home.about.items.length > 1 &&
                        <div class="col-12">
                            <div class="row py-lg-5">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div class="item" id="counter">
                                        <div class="row no-gutters">
                                            <div class="col-12 text-end">
                                                <h1 class="hyper d-flex justify-content-center">+
                                                    <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                                                        {({ isVisible }) => (
                                                            <span class="counter-value one">
                                                                { isVisible 
                                                                    ? <CountUp 
                                                                        start={0}
                                                                        duration={5}
                                                                        end={totalCountAll} /> 
                                                                        // end={realEstate.sections.home.about.items[0].value} /> 
                                                                    : null }
                                                            </span> )}
                                                    </VisibilitySensor>
                                                </h1>
                                            </div>
                                            <div class="col-12 px-3">
                                                <p className="p-40">{capitalizeText(realEstate.sections.home.about.items[0].paragraph)}</p>
                                                {/* <p>OPERACIONES INMOBILIARIAS <br class="d-none d-lg-block" />REALIZADAS.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div class="item">
                                        <div class="row no-gutters">
                                            <div class="col-12 text-end">
                                                <h1 class="hyper d-flex justify-content-center">+
                                                    <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                                                        {({ isVisible }) => (
                                                            <span class="counter-value one">
                                                                { isVisible 
                                                                    ? <CountUp 
                                                                        start={0}
                                                                        duration={5}
                                                                        end={realEstate.sections.home.about.items[1].value} /> 
                                                                    : null }
                                                            </span> )}
                                                    </VisibilitySensor>
                                                </h1>
                                            </div>
                                            <div class="col-12 px-3">
                                                <p className="p-45">{capitalizeText(realEstate.sections.home.about.items[1].paragraph)}</p>
                                                {/* <p>AÑOS DE EXPERIENCIA <br class="d-none d-lg-block" />EN EL MERCADO <br class="d-none d-lg-block" />INMOBILIARIO.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div class="item" id="counter">
                                        <div class="row no-gutters">
                                            <div class="col-12 text-end">
                                                <h1 class="hyper d-flex justify-content-center">+
                                                    <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                                                        {({ isVisible }) => (
                                                            <span class="counter-value one">
                                                                { isVisible 
                                                                    ? <CountUp 
                                                                        start={0}
                                                                        duration={5}
                                                                        end={realEstate.sections.home.about.items[2].value} /> 
                                                                    : null }
                                                            </span> )}
                                                    </VisibilitySensor>
                                                </h1>
                                            </div>
                                            <div class="col-12 px-3">
                                                <p>{capitalizeText(realEstate.sections.home.about.items[2].paragraph)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div class="item" id="counter">
                                        <div class="row no-gutters">
                                            <div class="col-12 text-end">
                                                <h1 class="hyper d-flex justify-content-center">+
                                                    <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                                                        {({ isVisible }) => (
                                                            <span class="counter-value one">
                                                                { isVisible 
                                                                    ? <CountUp 
                                                                        start={0}
                                                                        duration={5}
                                                                        end={realEstate.sections.home.about.items[3].value} /> 
                                                                    : null }
                                                            </span> )}
                                                    </VisibilitySensor>
                                                </h1>
                                            </div>
                                            <div class="col-12 px-3">
                                                <p>{capitalizeText(realEstate.sections.home.about.items[3].paragraph)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    }
                    <div class="col-12 d-flex justify-content-center">
                        <Link to={realEstate.sections.home.about.buttons[0].link} 
                            class="btn btn-red">
                                {capitalizeText(realEstate.sections.home.about.buttons[0].value)}
                                <i className="icon-arrow-stick-light"></i>
                        </Link>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        </section>
    )
}

export default connect(state => ({
    totalCountAlquiler: state.properties.totalCountAlquiler,
    totalCountVenta: state.properties.totalCountVenta,
    totalCountAlquilerTemporario:state.properties.totalCountAlquilerTemporario,
    totalCountAlquilerTemp:state.properties.totalCountAlquilerTemp,
    totalCountAll:state.properties.totalCountAll,
}),null)(About);