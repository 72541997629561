import React from 'react'
import { Link } from 'gatsby'
import phoneImg from '../../images/phone.png'

const Tasaciones = () => {
  return (
    <section className="tasaciones">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <img src={phoneImg} class="phone-img" alt="DIC - Encontra todo lo que buscas" />
                </div>
                <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                    <div className="text-container d-flex flex-column justify-content-center align-items-center">
                        <h2 class="title mb-3 mt-5 pt-4">TASAMOS TU PROPIEDAD DE FORMA RÁPIDA Y PROFESIONAL</h2>
                        <p class="text text-center my-5">Desde 1984 brindamos el mejor servicio inmobiliario del mercado, asesorándote personalmente sin compromiso, en todas tus operaciones inmobiliarias.</p>
                        <Link to="/tasaciones" class="btn btn-red mt-3">
                            Tasá tu propiedad ahora
                            <i className="icon-arrow-stick-light"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Tasaciones